


























































































































import { ref, watchEffect, computed } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any) {
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const isCreating = ref(false);
    const isDeleting = ref(false);
    const isDisable = ref(false);
    const currentId = ref('');
    const priorities = ref(['High', 'Medium', 'Low']);
    const qaItem: any = ref({
      id: uuidv4(),
      question: '',
      answer: '',
      priority: ''
    });
    const qaTable: any = ref([]);
    const headers = [
      { text: 'Question', value: 'question', width: '40%' },
      { text: 'Answer', value: 'answer', width: '40%' },
      { text: 'Priority', value: 'priority', width: '10%' },
      { text: 'Actions', value: 'actions', sortable: false, width: '10%' }
    ];

    const qaList: any = computed(() => {
      const priorityMap = { High: 0, Medium: 1, Low: 2 };
      return qaTable.value
        .slice()
        .sort((a, b) => priorityMap[a.priority] - priorityMap[b.priority]);
    });

    const isPriorityColor = value => {
      switch (value) {
        case 'Low':
          return 'orange';
        case 'Medium':
          return 'yellow';
        case 'High':
          return 'green';
        default:
          return '';
      }
    };

    async function saveProjectQA(val) {
      if (val === 'save') isCreating.value = true;
      else isDisable.value = true;
      try {
        const data = {
          projectUserQA: qaTable.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          if (val === 'save') msg.value = res.data.message;
          else if (val === 'delete') msg.value = 'Q & A deleted successfully';
          isCreating.value = false;
          isDisable.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isCreating.value = false;
        isDisable.value = false;
      }
    }

    async function populateQA() {
      qaTable.value.push(qaItem.value);
      qaItem.value = {
        question: '',
        answer: '',
        priority: ''
      };
      await saveProjectQA('save');
    }

    async function removeItem(item) {
      isDeleting.value = true;
      currentId.value = item.id;
      const index = qaTable.value?.findIndex(i => i.id === item.id);
      qaTable.value.splice(index, 1);
      await saveProjectQA('delete');
      isDeleting.value = false;
    }

    async function editItem(item) {
      isDeleting.value = true;
      qaItem.value = {
        id: item.id,
        question: item.question,
        answer: item.answer,
        priority: item.priority
      };
      const index = qaTable.value?.findIndex(i => i.id === item.id);
      qaTable.value.splice(index, 1);
      await saveProjectQA('edit');
      isDeleting.value = false;
    }

    watchEffect(() => {
      if (props.data.projectUserQA) {
        qaTable.value = props.data.projectUserQA;
      }
    });

    return {
      successMsg,
      errorMsg,
      msg,
      isCreating,
      populateQA,
      priorities,
      qaItem,
      headers,
      qaTable,
      saveProjectQA,
      qaList,
      isPriorityColor,
      removeItem,
      isDeleting,
      editItem,
      currentId,
      isDisable
    };
  }
};
